import {isEmpty} from "lodash-es";

/**
 * Transform object of key, value to string of query params
 */
export const transformQueryParams = (params: Record<string, unknown>) => {
  const cascadeObject = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value + "")}`)
    .join("&");

  const queryParams = !isEmpty(params) ? `?${cascadeObject}` : "";

  return queryParams;
};
